// Global Variables

@global_parent: 				grid;
@global_column_prefix: 			col;
@global_row_prefix: 			row;
@global_inner_offset_prefix: 	offset;

// Grid Оrientation | FlexBox Model |

.grid-row-flexmodel(@custom_row_prefix:row--flex,@display:flex,@flex-direction:row,@flex-wrap:wrap,@justify-content:inherit,@align-items:inherit,@align-content:auto){
	.@{custom_row_prefix} {

			display: @display;  // flex | inline-flex
    		flex-direction:@flex-direction; // row | row-reverse | column | column-reverse
    		flex-wrap:@flex-wrap;  //nowrap | wrap | wrap-reverse
    		justify-content:@justify-content; // flex-start | flex-end | center | space-between | space-around;
    		align-items:@align-items; // flex-start | flex-end | center | baseline | stretch;
    		align-content:@align-content; // flex-start | flex-end | center | space-between | space-around | stretch;

			// Note that float, clear and vertical-align have no effect on a flex item. More info > https://css-tricks.com/snippets/css/a-guide-to-flexbox/
	}
}

.grid-col-flexmodel(@custom_col_prefix:col--flex) {
	.@{custom_col_prefix} {
			order:1;
			flex-grow:0; // default 0
			flex-shrink:1; // 1
			flex-basis:auto; // <length> | auto;
			align-self:auto; // auto | flex-start | flex-end | center | baseline | stretch;

	}
}


// Grid Оrientation | Floats |

.grid-row-floats(@custom_row_prefix:row--left,@orientation:left,@width:100%) {
	.@{custom_row_prefix} {
		width:~"@{width}";
		float:~"@{orientation}";
	}
}

.grid-col-orientation(@custom_col_prefix:col,@display:block,@orientation:left) {
	.@{custom_col_prefix} {
		float:~"@{orientation}";
		display:@display;
	}
}



//  Grid Generators

//_______HEIGHT________________

.generate-vertical-grid(@n,@i:1,@prefix,@value:100,@unit) when (@i =< @n) {
	    @name:floor(@value / @i);
	    .@{global_column_prefix}-@{prefix}-@{name} {
	    	@math_value: @value / @i;
		    height: ~"@{math_value}@{unit}";
	  	}

	  	.generate-vertical-grid(@n, (@i + 1),@prefix,@value,@unit);
}

//_______WIDTH_________________

.generate-horizotal-grid(@n,@i:1,@prefix,@value:100,@unit,@square:false) when (@i =< @n) {
	 	@name:floor(@value / @i);
	    .@{global_column_prefix}-@{prefix}-@{name} {
		    @math_value: @value / @i;
		    width: ~"@{math_value}@{unit}";

			& when (@square = true) {
			    &.abs-square {
			    	position:relative;
		        	padding-bottom: ~"@{math_value}@{unit}";
		        	&.half {
		        		@half_math:(@value / @i)/2;
			    		padding-bottom: ~"@{half_math}@{unit}";
			    	}
			    }
			}

	  	}

	  	.generate-horizotal-grid(@n, (@i + 1),@prefix,@value,@unit,@square);
}


//________OFFSET____________

.generate-offset(@n,@i:1,@prefix,@propertie,@value:100,@unit) when (@i =< @n) {
	    @name:@i;
	    .@{global_inner_offset_prefix}-@{prefix}-@{name} {
	    	@math_value: @value / @i;
		    @{propertie}: ~"@{math_value}@{unit}";
			box-sizing:border-box;
	  	}

	  	.generate-offset(@n, (@i + 1),@prefix,@propertie,@value,@unit);
}


// Default SetUp

.generate-horizotal-grid(10,1,d,100,"%",true);

@media @tablet {
	.generate-horizotal-grid(10,1,t,100,"%",true);
}
@media @mobile {
	.generate-horizotal-grid(10,1,m,100,"%",true);
}

.generate-offset(5,1,d,padding,5,"rem");

.grid-row-floats;
.grid-col-orientation;


