.footer{
	background-color: @black;
}

.footer-holder{
	max-width: 1390px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px 40px;
    .bx;
	p{
		font-family: @roboto;
		font-weight: 400;
		font-size: 12px;
		color: #a8a8a8;
		margin: 0;
	}
}