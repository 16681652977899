@purple: #62269e;

body{
	background-color: @black;
}

.hero-section{
	background-size: cover;
}

.hero-holder{
	max-width: 1480px;
	width: 100%;
	margin: 0 auto;
	.vw(padding-top, 142px);
	.vw(padding-bottom, 132px);
	padding-left: 40px;
	padding-right: 40px;
	.bx;
	@media @mobile_all{
		padding-left: 25px;
		padding-right: 25px;
	}
	@media @mobile{
		padding-top: 85px;
	}
	&.help-holder{
		padding-left: 40px;
		padding-right: 40px;
		.bx;
		@media @mobile_all{
			padding-left: 25px;
			padding-right: 25px;
		}
		padding-bottom: 0;
		.vw(padding-top, 120px);
	}
} 

.logo-holder{
	max-width: 1480px;
	width: 100%;
	margin: 0 auto;
	.vw(padding-top, 114px);
	padding-left: 40px;
	padding-right: 40px;
	.bx;
	@media @mobile_all{
		padding-left: 25px;
		padding-right: 25px;
	}
	img{
		@media @mobile_all{
			max-width: 100px;
		}
	}
}

.hero-title{
	font-family: @roboto;
	.vw(font-size, 80px);
	font-weight: 300;
	line-height: 1;
	@media @mobile_all{
		font-size: 45px;
		line-height: 1;
	}
	strong{
		font-weight: 700;
	}
}

.hero-para{
	font-family: @roboto;
	font-size: 20px;
	font-weight: 400;
	color: @white;
	@media @mobile_all{
		font-size: 18px;
	}
	a{
		color: @white;
		.prel;
		@media @mobile_all{
			text-decoration: underline;
		}
		&:before{
			@media @min1024{
				content: '';
				position: absolute;
				bottom: 0px;
				width: 100%;
				height: 1px;
				background-color: @white;
				transition: width .3s ease-in-out;
			}
		}
		&:hover{
			&:before{
				width: 70%;
				transition: width .3s ease-in-out;
			}
		}
	}
}

.hero-txt-holder{
	max-width: 1160px;
	.hero-title{
		@media @min1024{
			margin-bottom: 30px;
		}
	}
}
/* Donate section */

.donate-section{
	background-color: #020002;
}

.donate-holder{
	max-width: 1630px;
	width: 100%;
	margin: 0 auto;
	background-color: @purple;
	padding-bottom: 84px;
	padding-top: 94px;
	@media @mobile_all{
		padding-top: 45px;
		padding-bottom: 45px;
	}
}

.donate-inner{
	max-width: 1480px;
	width: 100%;
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;
	.bx;
	@media @mobile_all{
		padding-left: 25px;
		padding-right: 25px;
	}
}

.para-22{
	font-family: @roboto;
	font-size: 22px;
	color: @white;
	@media @mobile_all{
		font-size: 20px;
	}
}

.progress-holder{
	.vw(padding-top, 100px);
}

.sum-txt{
	font-family: @roboto;
	font-size: 33px;
	color: @white;
	font-weight: 300;
	strong{
		font-size: 62px;
		font-weight: 700;
	}
	@media @mobile_all{
		font-size: 20px;
		strong{
			font-size: 40px;
			line-height: 1;
		}
	}
	@media @mobile{
		strong{
			display: block;
		}
	}
}

.progress-bar{
	width: 100%;
	padding: 14px;
	border-radius: 50px;
	background-color: @white;
	.bx;
	margin-top: 18px;
	margin-bottom: 40px;
	.progress-line{
		height: 26px;
		width: 20%;
		background-color: @purple;
		border-radius: 50px 0 0 50px;
	}
}

.heart-image{
	float: right;
	top: -48px;
	.prel;
	animation: heartbeat 1s infinite;
}

@keyframes heartbeat
{
	0%{
	   transform: scale(.75);
	}
	20%{
		transform: scale(1);
	}
	40%{
		transform: scale(.75);
	}
	60%{
		transform: scale(1);
	}
	80%{
		transform: scale(.75);
	}
	100%{
		transform: scale(.75);
	}
}

.donate-btn{
	font-family: @roboto;
	font-size: 25px;
	font-weight: 700;
	line-height: 1;
	color: @white;
	background-color: @black;
	padding: 30px;
	.bx;
	width: 100%;
	max-width: 400px;
	display: block;
	border-radius: 50px;
	text-align: center;
	.upper;
	transition: background-color .3s ease-in-out;
	@media @mobile_all{
		font-size: 20px;
		padding: 25px;
	}
	&:hover{
		background-color: #272727;
		transition: background-color .3s ease-in-out;
	}
}

.donate-txt{
	max-width: 1630px;
    width: 100%;
    margin: 0 auto;
    img{
    	width: 100%;
    	.prel;
    	top: -52px;
    	@media @mobile_all{
    		top: -4vw;
    	}
    }
}

/* Help section */

.help-section{
	background-color: @black;
	background-size: cover;
}

.help-para{
	font-family: @roboto;
	font-size: 36px;
	color: @white;
	font-weight: 400;
	line-height: 1;
	@media @mobile_all{
		font-size: 30px;
	}
	strong{
		font-weight: 700;
	}
	a{
		color: @purple;
		.prel;
		line-height: 1;
		@media @mobile_all{
			text-decoration: underline;
		}
		&:before{
			@media @min1024{
				content: '';
				position: absolute;
				bottom: 4px;
				width: 100%;
				height: 1px;
				background-color: @purple;
				transition: width .3s ease-in-out;
			}
		}
		&:hover{
			&:before{
				width: 70%;
				transition: width .3s ease-in-out;
			}
		}
	}
}

.help-txt-holder{
	max-width: 1160px;
	.vw(padding-bottom, 400px);
	@media @mobile_all{
		padding-bottom: 45px;
	}
}

/* Others section */

.others-holder{
	max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    padding-left: 40px;
	padding-right: 40px;
	.bx;
	@media @mobile_all{
		padding-left: 25px;
		padding-right: 25px;
	}
}

.col-title{
	font-family: @roboto;
	font-size: 35px;
	font-weight: 300;
	line-height: 1;
	margin-bottom: 30px;
	@media @mobile_all{
		font-size: 30px;
	}
	strong{
		font-weight: 700;
	}
}

.col-para{
	font-family: @roboto;
	font-size: 16px;
	color: @white;
	font-weight: 400;
	line-height: 1.5;
	a{
		.prel;
		color: @white;
		display: inline-block;
		font-weight: 700;
		&:before{
			content: '';
			position: absolute;
			bottom: 4px;
			width: 100%;
			height: 1px;
			background-color: @white;
			transition: width .3s ease-in-out;
		}
		&:hover{
			&:before{
				width: 70%;
				transition: width .3s ease-in-out;
			}
		}
	}
}

.cols-holder{
	max-width: 1537px;
    margin: 0 auto;
    .vw(padding-left, 33px);
    .vw(padding-top, 85px);
    @media @mobile_all{
    	padding-left: 0;
    	padding-top: 45px;
    }
    @media @min1024{
    	padding-right: 25px;
    }
    @media @min1440{
		.vw(padding-left, 127px);
    }
    @media @min640{
    	display: flex;
    	flex-wrap: wrap;
    }
	.col{
		padding: 0 17px;
    	box-sizing: border-box;
    	background: #62269e;
    	background-clip: content-box;
    	@media @mobile_all{
    		padding-bottom: 17px;
    	}
	}
}


.col-inner{
	background-color: @purple;
	padding: 50px 40px;
	.bx;
}

/* Thank you section */

.thankyou-section{
	background-color: @black;
}

.thankyou-holder{
	max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    .vw(padding-top, 140px);
    .vw(padding-bottom, 190px);
    padding-left: 40px;
	padding-right: 40px;
	.bx;
	@media @mobile_all{
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 45px;
		padding-bottom: 45px;
	}
}

.signature-para{
	font-family: @roboto;
	font-size: 25px;
	font-weight: 700;
}

.signature-link{
	font-family: @roboto;
	font-size: 25px;
	font-weight: 700;
	color: @white;
	.prel;
	display: block;
	@media @mobile_all{
		text-decoration: underline;
	}
	&:before{
		@media @min1024{
			content: '';
			position: absolute;
			bottom: 0px;
			width: 100%;
			height: 1px;
			background-color: @white;
			transition: width .3s ease-in-out;
		}
	}
	&:hover{
		&:before{
			width: 70%;
			transition: width .3s ease-in-out;
		}
	}
}

.signature-holder{
	padding-top: 40px;
	@media @min640{
		display: flex;
	}
}

.img-col{
	padding-right: 30px;
}