html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  color: #fff;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
blockquote {
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
}
button:focus {
  outline: 0;
}
a img {
  border: 0 none;
}
a {
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}
.f0 {
  font-size: 0;
}
.object {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.clr {
  clear: both;
}
.ul-reset {
  margin: 0;
  padding: 0;
}
.ul-reset li {
  list-style-type: none;
}
.h-reset h1,
.h-reset h2,
.h-reset h3,
.h-reset h4,
.h-reset p {
  margin: 0;
}
.w-reset {
  max-width: 100%;
  width: 100%;
}
.h-reset {
  height: auto;
}
.block {
  display: block;
}
.inline_block {
  display: inline-block;
}
.none {
  display: none;
}
.ov-hidden {
  overflow: hidden;
}
.ov-visible {
  overflow: visible;
}
.ov-o-hidden {
  opacity: 0;
  visibility: hidden;
}
.ov-o-visible {
  opacity: 1;
  visibility: visible;
}
.vm {
  vertical-align: middle;
}
.vt {
  vertical-align: top;
}
.vb {
  vertical-align: bottom;
}
input,
select,
textarea {
  outline: none;
}
input,
textarea {
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
}
select {
  outline: none;
  border-radius: 0;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  /* 1 */
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
blockquote {
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
}
button:focus {
  outline: 0;
}
.center {
  text-align: center;
}
.upper {
  text-transform: uppercase;
}
.lower {
  text-transform: lowercase;
}
.case {
  text-transform: normal;
}
.nocase {
  text-transform: none;
}
.italic {
  font-style: italic;
}
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.pull-none {
  float: none;
}
.prel {
  position: relative;
}
.pabs {
  position: absolute;
}
.pfix {
  position: fixed;
}
.valign {
  position: relative;
  display: table;
}
.valign.fit {
  width: 100%;
  height: 100%;
}
.valign .middle {
  display: table-cell;
  vertical-align: middle;
}
.middle {
  vertical-align: middle;
}
.bx {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.fit {
  width: 100%;
  height: 100%;
}
@media (min-width: 1025px) {
  .mobile-only {
    display: none;
  }
}
.desktop-only {
  display: none;
}
@media (min-width: 1025px) {
  .desktop-only {
    display: block;
  }
}
.abs-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.col-d-100 {
  width: 100%;
}
.col-d-100.abs-square {
  position: relative;
  padding-bottom: 100%;
}
.col-d-100.abs-square.half {
  padding-bottom: 50%;
}
.col-d-50 {
  width: 50%;
}
.col-d-50.abs-square {
  position: relative;
  padding-bottom: 50%;
}
.col-d-50.abs-square.half {
  padding-bottom: 25%;
}
.col-d-33 {
  width: 33.333333333333336%;
}
.col-d-33.abs-square {
  position: relative;
  padding-bottom: 33.333333333333336%;
}
.col-d-33.abs-square.half {
  padding-bottom: 16.666666666666668%;
}
.col-d-25 {
  width: 25%;
}
.col-d-25.abs-square {
  position: relative;
  padding-bottom: 25%;
}
.col-d-25.abs-square.half {
  padding-bottom: 12.5%;
}
.col-d-20 {
  width: 20%;
}
.col-d-20.abs-square {
  position: relative;
  padding-bottom: 20%;
}
.col-d-20.abs-square.half {
  padding-bottom: 10%;
}
.col-d-16 {
  width: 16.666666666666668%;
}
.col-d-16.abs-square {
  position: relative;
  padding-bottom: 16.666666666666668%;
}
.col-d-16.abs-square.half {
  padding-bottom: 8.333333333333334%;
}
.col-d-14 {
  width: 14.285714285714286%;
}
.col-d-14.abs-square {
  position: relative;
  padding-bottom: 14.285714285714286%;
}
.col-d-14.abs-square.half {
  padding-bottom: 7.142857142857143%;
}
.col-d-12 {
  width: 12.5%;
}
.col-d-12.abs-square {
  position: relative;
  padding-bottom: 12.5%;
}
.col-d-12.abs-square.half {
  padding-bottom: 6.25%;
}
.col-d-11 {
  width: 11.11111111111111%;
}
.col-d-11.abs-square {
  position: relative;
  padding-bottom: 11.11111111111111%;
}
.col-d-11.abs-square.half {
  padding-bottom: 5.555555555555555%;
}
.col-d-10 {
  width: 10%;
}
.col-d-10.abs-square {
  position: relative;
  padding-bottom: 10%;
}
.col-d-10.abs-square.half {
  padding-bottom: 5%;
}
@media (min-width: 720px) and (max-width: 1024px) {
  .col-t-100 {
    width: 100%;
  }
  .col-t-100.abs-square {
    position: relative;
    padding-bottom: 100%;
  }
  .col-t-100.abs-square.half {
    padding-bottom: 50%;
  }
  .col-t-50 {
    width: 50%;
  }
  .col-t-50.abs-square {
    position: relative;
    padding-bottom: 50%;
  }
  .col-t-50.abs-square.half {
    padding-bottom: 25%;
  }
  .col-t-33 {
    width: 33.333333333333336%;
  }
  .col-t-33.abs-square {
    position: relative;
    padding-bottom: 33.333333333333336%;
  }
  .col-t-33.abs-square.half {
    padding-bottom: 16.666666666666668%;
  }
  .col-t-25 {
    width: 25%;
  }
  .col-t-25.abs-square {
    position: relative;
    padding-bottom: 25%;
  }
  .col-t-25.abs-square.half {
    padding-bottom: 12.5%;
  }
  .col-t-20 {
    width: 20%;
  }
  .col-t-20.abs-square {
    position: relative;
    padding-bottom: 20%;
  }
  .col-t-20.abs-square.half {
    padding-bottom: 10%;
  }
  .col-t-16 {
    width: 16.666666666666668%;
  }
  .col-t-16.abs-square {
    position: relative;
    padding-bottom: 16.666666666666668%;
  }
  .col-t-16.abs-square.half {
    padding-bottom: 8.333333333333334%;
  }
  .col-t-14 {
    width: 14.285714285714286%;
  }
  .col-t-14.abs-square {
    position: relative;
    padding-bottom: 14.285714285714286%;
  }
  .col-t-14.abs-square.half {
    padding-bottom: 7.142857142857143%;
  }
  .col-t-12 {
    width: 12.5%;
  }
  .col-t-12.abs-square {
    position: relative;
    padding-bottom: 12.5%;
  }
  .col-t-12.abs-square.half {
    padding-bottom: 6.25%;
  }
  .col-t-11 {
    width: 11.11111111111111%;
  }
  .col-t-11.abs-square {
    position: relative;
    padding-bottom: 11.11111111111111%;
  }
  .col-t-11.abs-square.half {
    padding-bottom: 5.555555555555555%;
  }
  .col-t-10 {
    width: 10%;
  }
  .col-t-10.abs-square {
    position: relative;
    padding-bottom: 10%;
  }
  .col-t-10.abs-square.half {
    padding-bottom: 5%;
  }
}
@media (max-width: 719px) {
  .col-m-100 {
    width: 100%;
  }
  .col-m-100.abs-square {
    position: relative;
    padding-bottom: 100%;
  }
  .col-m-100.abs-square.half {
    padding-bottom: 50%;
  }
  .col-m-50 {
    width: 50%;
  }
  .col-m-50.abs-square {
    position: relative;
    padding-bottom: 50%;
  }
  .col-m-50.abs-square.half {
    padding-bottom: 25%;
  }
  .col-m-33 {
    width: 33.333333333333336%;
  }
  .col-m-33.abs-square {
    position: relative;
    padding-bottom: 33.333333333333336%;
  }
  .col-m-33.abs-square.half {
    padding-bottom: 16.666666666666668%;
  }
  .col-m-25 {
    width: 25%;
  }
  .col-m-25.abs-square {
    position: relative;
    padding-bottom: 25%;
  }
  .col-m-25.abs-square.half {
    padding-bottom: 12.5%;
  }
  .col-m-20 {
    width: 20%;
  }
  .col-m-20.abs-square {
    position: relative;
    padding-bottom: 20%;
  }
  .col-m-20.abs-square.half {
    padding-bottom: 10%;
  }
  .col-m-16 {
    width: 16.666666666666668%;
  }
  .col-m-16.abs-square {
    position: relative;
    padding-bottom: 16.666666666666668%;
  }
  .col-m-16.abs-square.half {
    padding-bottom: 8.333333333333334%;
  }
  .col-m-14 {
    width: 14.285714285714286%;
  }
  .col-m-14.abs-square {
    position: relative;
    padding-bottom: 14.285714285714286%;
  }
  .col-m-14.abs-square.half {
    padding-bottom: 7.142857142857143%;
  }
  .col-m-12 {
    width: 12.5%;
  }
  .col-m-12.abs-square {
    position: relative;
    padding-bottom: 12.5%;
  }
  .col-m-12.abs-square.half {
    padding-bottom: 6.25%;
  }
  .col-m-11 {
    width: 11.11111111111111%;
  }
  .col-m-11.abs-square {
    position: relative;
    padding-bottom: 11.11111111111111%;
  }
  .col-m-11.abs-square.half {
    padding-bottom: 5.555555555555555%;
  }
  .col-m-10 {
    width: 10%;
  }
  .col-m-10.abs-square {
    position: relative;
    padding-bottom: 10%;
  }
  .col-m-10.abs-square.half {
    padding-bottom: 5%;
  }
}
.offset-d-1 {
  padding: 5rem;
  box-sizing: border-box;
}
.offset-d-2 {
  padding: 2.5rem;
  box-sizing: border-box;
}
.offset-d-3 {
  padding: 1.6666666666666667rem;
  box-sizing: border-box;
}
.offset-d-4 {
  padding: 1.25rem;
  box-sizing: border-box;
}
.offset-d-5 {
  padding: 1rem;
  box-sizing: border-box;
}
.row--left {
  width: 100%;
  float: left;
}
.col {
  float: left;
  display: block;
}
.header {
  max-width: 1507px;
  margin: 0 auto;
  position: fixed;
  width: 100%;
  padding-top: 110px;
}
.footer {
  background-color: #000;
}
.footer-holder {
  max-width: 1390px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.footer-holder p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #a8a8a8;
  margin: 0;
}
body {
  background-color: #000;
}
.hero-section {
  background-size: cover;
}
.hero-holder {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding-top: 7.4vw;
  padding-bottom: 6.88vw;
  padding-left: 40px;
  padding-right: 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .hero-holder {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 719px) {
  .hero-holder {
    padding-top: 85px;
  }
}
.hero-holder.help-holder {
  padding-left: 40px;
  padding-right: 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 0;
  padding-top: 6.25vw;
}
@media (max-width: 1024px) {
  .hero-holder.help-holder {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.logo-holder {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding-top: 5.94vw;
  padding-left: 40px;
  padding-right: 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .logo-holder {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 1024px) {
  .logo-holder img {
    max-width: 100px;
  }
}
.hero-title {
  font-family: 'Roboto', sans-serif;
  font-size: 4.17vw;
  font-weight: 300;
  line-height: 1;
}
@media (max-width: 1024px) {
  .hero-title {
    font-size: 45px;
    line-height: 1;
  }
}
.hero-title strong {
  font-weight: 700;
}
.hero-para {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
@media (max-width: 1024px) {
  .hero-para {
    font-size: 18px;
  }
}
.hero-para a {
  color: #fff;
  position: relative;
}
@media (max-width: 1024px) {
  .hero-para a {
    text-decoration: underline;
  }
}
@media (min-width: 1025px) {
  .hero-para a:before {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: width 0.3s ease-in-out;
  }
}
.hero-para a:hover:before {
  width: 70%;
  transition: width 0.3s ease-in-out;
}
.hero-txt-holder {
  max-width: 1160px;
}
@media (min-width: 1025px) {
  .hero-txt-holder .hero-title {
    margin-bottom: 30px;
  }
}
/* Donate section */
.donate-section {
  background-color: #020002;
}
.donate-holder {
  max-width: 1630px;
  width: 100%;
  margin: 0 auto;
  background-color: #62269e;
  padding-bottom: 84px;
  padding-top: 94px;
}
@media (max-width: 1024px) {
  .donate-holder {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
.donate-inner {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .donate-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.para-22 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  color: #fff;
}
@media (max-width: 1024px) {
  .para-22 {
    font-size: 20px;
  }
}
.progress-holder {
  padding-top: 5.21vw;
}
.sum-txt {
  font-family: 'Roboto', sans-serif;
  font-size: 33px;
  color: #fff;
  font-weight: 300;
}
.sum-txt strong {
  font-size: 62px;
  font-weight: 700;
}
@media (max-width: 1024px) {
  .sum-txt {
    font-size: 20px;
  }
  .sum-txt strong {
    font-size: 40px;
    line-height: 1;
  }
}
@media (max-width: 719px) {
  .sum-txt strong {
    display: block;
  }
}
.progress-bar {
  width: 100%;
  padding: 14px;
  border-radius: 50px;
  background-color: #fff;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 18px;
  margin-bottom: 40px;
}
.progress-bar .progress-line {
  height: 26px;
  width: 20%;
  background-color: #62269e;
  border-radius: 50px 0 0 50px;
}
.heart-image {
  float: right;
  top: -48px;
  position: relative;
  animation: heartbeat 1s infinite;
}
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
.donate-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  background-color: #000;
  padding: 30px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  display: block;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .donate-btn {
    font-size: 20px;
    padding: 25px;
  }
}
.donate-btn:hover {
  background-color: #272727;
  transition: background-color 0.3s ease-in-out;
}
.donate-txt {
  max-width: 1630px;
  width: 100%;
  margin: 0 auto;
}
.donate-txt img {
  width: 100%;
  position: relative;
  top: -52px;
}
@media (max-width: 1024px) {
  .donate-txt img {
    top: -4vw;
  }
}
/* Help section */
.help-section {
  background-color: #000;
  background-size: cover;
}
.help-para {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  color: #fff;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 1024px) {
  .help-para {
    font-size: 30px;
  }
}
.help-para strong {
  font-weight: 700;
}
.help-para a {
  color: #62269e;
  position: relative;
  line-height: 1;
}
@media (max-width: 1024px) {
  .help-para a {
    text-decoration: underline;
  }
}
@media (min-width: 1025px) {
  .help-para a:before {
    content: '';
    position: absolute;
    bottom: 4px;
    width: 100%;
    height: 1px;
    background-color: #62269e;
    transition: width 0.3s ease-in-out;
  }
}
.help-para a:hover:before {
  width: 70%;
  transition: width 0.3s ease-in-out;
}
.help-txt-holder {
  max-width: 1160px;
  padding-bottom: 20.83vw;
}
@media (max-width: 1024px) {
  .help-txt-holder {
    padding-bottom: 45px;
  }
}
/* Others section */
.others-holder {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .others-holder {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.col-title {
  font-family: 'Roboto', sans-serif;
  font-size: 35px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .col-title {
    font-size: 30px;
  }
}
.col-title strong {
  font-weight: 700;
}
.col-para {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
}
.col-para a {
  position: relative;
  color: #fff;
  display: inline-block;
  font-weight: 700;
}
.col-para a:before {
  content: '';
  position: absolute;
  bottom: 4px;
  width: 100%;
  height: 1px;
  background-color: #fff;
  transition: width 0.3s ease-in-out;
}
.col-para a:hover:before {
  width: 70%;
  transition: width 0.3s ease-in-out;
}
.cols-holder {
  max-width: 1537px;
  margin: 0 auto;
  padding-left: 1.72vw;
  padding-top: 4.43vw;
}
@media (max-width: 1024px) {
  .cols-holder {
    padding-left: 0;
    padding-top: 45px;
  }
}
@media (min-width: 1025px) {
  .cols-holder {
    padding-right: 25px;
  }
}
@media (min-width: 1441px) {
  .cols-holder {
    padding-left: 6.61vw;
  }
}
@media (min-width: 640px) {
  .cols-holder {
    display: flex;
    flex-wrap: wrap;
  }
}
.cols-holder .col {
  padding: 0 17px;
  box-sizing: border-box;
  background: #62269e;
  background-clip: content-box;
}
@media (max-width: 1024px) {
  .cols-holder .col {
    padding-bottom: 17px;
  }
}
.col-inner {
  background-color: #62269e;
  padding: 50px 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
/* Thank you section */
.thankyou-section {
  background-color: #000;
}
.thankyou-holder {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding-top: 7.29vw;
  padding-bottom: 9.9vw;
  padding-left: 40px;
  padding-right: 40px;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .thankyou-holder {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
.signature-para {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  font-weight: 700;
}
.signature-link {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  position: relative;
  display: block;
}
@media (max-width: 1024px) {
  .signature-link {
    text-decoration: underline;
  }
}
@media (min-width: 1025px) {
  .signature-link:before {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: width 0.3s ease-in-out;
  }
}
.signature-link:hover:before {
  width: 70%;
  transition: width 0.3s ease-in-out;
}
.signature-holder {
  padding-top: 40px;
}
@media (min-width: 640px) {
  .signature-holder {
    display: flex;
  }
}
.img-col {
  padding-right: 30px;
}
